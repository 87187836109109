import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import pageData from "../../content/rooms/room-4.yml"
import { graphql } from 'gatsby';
import Post from '../components/post';
import ReactPlayer from 'react-player'

class Room5 extends React.Component {
  render() {
    const siteTitle = ""
    const { nodes } = this.props.data.allMarkdownRemark
    const { type, stream_url } = pageData;
    console.log(type)
    return (
      <Layout bg={pageData.background} color={pageData.color} location={this.props.location} title={siteTitle}>
        <SEO
          title="Room 5"
        />
       {type === 'feed' ? nodes.map((node, index) => 
          <Post color={pageData.color} {...node} key={`post-room-5--${index}`}/>
        ):
        <div className="video">
          <ReactPlayer
            url={stream_url}
            width="100%"
            height="100%"
            className="video_full"
            playing={true}
            loop={true}
            playsinline={true}
            config={{
              youtube: {
                playerVars: { showinfo: 0, controls:0, modestbranding: 1 }
              },
            }}
          />
        </div>
        }
      </Layout>
    )
  }
}

export default Room5

export const pageQuery = graphql`
query MyQueryRoom5 {
  allMarkdownRemark(filter: {frontmatter: {room: {eq: "room_5"}}}) {
    nodes {
      htmlAst
      frontmatter {
        title
        room
      }
    }
  }
}
`